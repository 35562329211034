/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import routesMap from '../../../../../routesMap'
import PermissionWrapper from 'src/app/components/utils/permission-wrapper'
import { useSelector } from 'react-redux'
import { SessionService } from 'src/services'
import { SolutionOutlined, QrcodeOutlined, TeamOutlined } from '@ant-design/icons' // ant

//
export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : ''
  }
  //
  const updateMenuTours = useSelector((state) => state.refreshTours)

  const [toursList, setTourList] = useState([])

  const getListTours = async () => {
    try {
      let response = await SessionService.getSession()
      if (response.status === 'OK') {
        setTourList(response?.data?.tours || [])
      }
    } catch (error) {}
  }

  useEffect(() => {
    getListTours()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMenuTours])

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/* dashboard */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.visitasBooking.path, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={routesMap.visitasBooking.path}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/tappit/house.svg')} />
            </span>
            <span className="menu-text">{routesMap.visitasBooking.name}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.business.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.business.path}>
              <span className="svg-icon menu-icon">
                <span className="fa fa-calendar"></span>
              </span>
              <span className="menu-text">{routesMap.business.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>

        <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.business.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.business.path}>
              <span className="svg-icon menu-icon">
                <span className="fa fa-comments"></span>
              </span>
              <span className="menu-text">{routesMap.business.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>

        <PermissionWrapper modules={['mdu_user', 'mdu_permission_user']}>
          <li
            className={`menu-item ${(getMenuItemActive(routesMap.user.path), false)}`}
            aria-haspopup="true"
          >
            {/* <NavLink className="menu-link menu-toggle" to="/user">
              <span className="menu-icon">
                <span className="fa fa-users"></span>
              </span>
              <span className="menu-text">Usuarios</span>
              <i className="menu-arrow" />
            </NavLink> */}

            {/* <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Usuarios</span>
                  </span>
                </li> */}

            <PermissionWrapper module="mdu_user">
              <NavLink className="menu-link" to={routesMap.user.path}>
                <span className="menu-icon">
                  <span className="fa fa-users"></span>
                </span>
                <span className="menu-text">{routesMap.user.name}</span>
              </NavLink>
            </PermissionWrapper>
            {/* </ul> */}
            {/* </div> */}
          </li>
          <li
            className={`menu-item ${(getMenuItemActive(routesMap.permission.path), false)}`}
            aria-haspopup="true"
          >
            {
              <PermissionWrapper module="mdu_permission_user">
                <NavLink className="menu-link" to={routesMap.permission.path}>
                  <span className="menu-icon">
                    <SolutionOutlined />
                    {/* Este es el icono que se mostrará */}
                  </span>
                  <span className="menu-text">{routesMap.permission.name}</span>
                </NavLink>
              </PermissionWrapper>
            }
          </li>
          <li
            className={`menu-item ${(getMenuItemActive(routesMap.checkins.path), false)}`}
            aria-haspopup="true"
          >
            {
              <PermissionWrapper module="mdu_permission_user">
                <NavLink className="menu-link" to={routesMap.checkins.path}>
                  <span className="menu-icon">
                    <QrcodeOutlined />
                    {/* Este es el icono que se mostrará */}
                  </span>
                  <span className="menu-text">{routesMap.checkins.name}</span>
                </NavLink>
              </PermissionWrapper>
            }
          </li>

          {/* Sección para ERP */}
          <li
            className={`menu-item ${(getMenuItemActive(routesMap.rp.path), false)}`}
            aria-haspopup="true"
          >
            <PermissionWrapper module="mdu_permission_user">
              <NavLink className="menu-link" to={routesMap.rp.path}>
                <span className="menu-icon">
                  <TeamOutlined />
                  {/* Cambiado a ícono de grupo de usuarios */}
                </span>
                <span className="menu-text">{routesMap.rp.name}</span>
              </NavLink>
            </PermissionWrapper>
          </li>
        </PermissionWrapper>

        {/* SECCION CUENTAS */}

        <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.business.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.business.path}>
              <span className="svg-icon menu-icon">
                <span className="fa fa-building"></span>
              </span>
              <span className="menu-text">{routesMap.business.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>

        <PermissionWrapper modules={['mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.settings.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.settings.path}>
              <span className="svg-icon menu-icon">
                {/* <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Tools/Settings.svg")}
                  className="svg-icon svg-icon-lg"
                ></SVG> */}
                <span className="fas fa-cog"></span>
              </span>
              <span className="menu-text">{routesMap.settings.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
