import React, { Suspense, lazy, useState } from 'react';
import { Card, Space, Button, Table, Tooltip, Row, Col } from 'antd'
import { PlusOutlined, ReloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import useTransportHook from './hook'
import { constants, utils } from 'src/utils'
import TransportForm from './form/transport-form';
import PermissionWrapper from 'src/app/components/utils/permission-wrapper';
import SubMenu from '../ExamplesConfig/SubMenu';
// import SettingsForm from '../ExamplesConfig/SettingsForm';

import { Switch, Route } from "react-router-dom";
import Ala from '../ExamplesConfig/SettingsReservaciones';
import { LayoutSplashScreen } from 'src/_metronic/layout';
const SettingsForm = lazy(() => import("../ExamplesConfig/SettingsGeneral"));

// const Transport = () => {
const Settings = () => {
  const [settingFormInfo, setSettingFormInfo] = useState("Configuración General");
  const [listMenu, setListMenu] = useState([
    { title: 'Ubicación', list: ['General', 'Lista de espera', 'Reservaciones'] },
    { title: 'Clientes', list: ['Campos de entrada', 'Etiquetas de entrada'] },
    { title: 'Equipo', list: ['Usuarios', 'Roles', 'Recursos'] } // Nueva sección "Equipo"
  ]);
  // const {
  //   loading,
  //   tableState,
  //   formState,
  //   refreshTable,
  //   handleTableChange,
  //   getData,
  //   openForm,
  //   closeForm,
  //   deleteItem,
  //   onChangeEnabled,
  // } = useTransportHook()
  //CODIGO DE EJEMPLO PARA EL RENDERIZADO DINÁMICO DE LOS MENÚS CON UNA LISTA DE CONFIGURACIONES
  // Esta es la primera versión del panel de ejemplo
  const selectSetting = (settingSelected) => {
    console.log(settingSelected);
    switch (settingSelected) {
      case 'general': setSettingFormInfo("Configuración General");
        break;
      case 'lista de espera': setSettingFormInfo("Lista de espera");
        break;
      case 'reservaciones': setSettingFormInfo("Reservaciones");
        break;
      case 'campos de entrada': setSettingFormInfo("Campos de entrada");
        break;
      case 'etiquetas de entrada': setSettingFormInfo("Etiquetas de entrada");
        break;
      default: setSettingFormInfo("Configuración General");
        break;
    }
  }
  return (
    <div>
      {/* CODIGO DE EJEMPLO PARA EL RENDERIZADO DINÁMICO DE LOS MENÚS CON UNA LISTA DE CONFIGURACIONES */}
      <Row>
        <Col xs={5}>
          {/* ESTE COMPONENTE RENDERIZA LA LISTA DE CONFIGURACIONES QUE SE LE PASEN */}
          <SubMenu
            //ESTE ARREGLO PUEDE SER CAMBIADO ENVIANDO DIFERENTES ARREGLOS CON EL TÍTULO Y LA LISTA DE CONFIGURACIONES
            settingsList={listMenu}
          // selectedSetting={selectSetting}
          />
        </Col>
        <Col xs={19} className='pl-2'>
          {/* ESTE COMPONENTE RECIBE LA INFORMACIÓN QUE SERÁ RENDERIZADA, FALTA DEFINIR SI EL FORM SERÁ RENDERIZADO POR MEDIO DE UN OBJETO O POR DIFERENTES COMPONENTES DADO QUE CAMBIAN VARIAS 
              OPCIONES DENTRO DEL DISEÑO */}
          <Card className='bg-white rounded shadow-none'>
            <h2 className='fw-bold'>{settingFormInfo ? settingFormInfo : null}</h2>
          </Card>
          <Card className='bg-white rounded shadow-none'>
            Holaa
          </Card>
        </Col>
      </Row>

      {/* <Card title={'Transportes'}> */}
      {/* buttons */}

      {/* <Space>
          <PermissionWrapper module={'mdu_business_config'} create>
            <Button type="primary" onClick={ e => openForm(null) } icon={ <PlusOutlined /> }>{ 'Nuevo' }</Button>
          </PermissionWrapper>
          <Button type="default" onClick={ e => refreshTable() } icon={ <ReloadOutlined /> }>{ 'Actualizar' }</Button>
        </Space> */}

      {/* table */}

      {/* <br />
        <br />
        
        <Table
          columns={[
            {
              title: 'Nombre',
              dataIndex: 'name',
              key: 'name',
              sorter: true,
              width: '20%',
              ...utils.getColumnSearchProps('name'),
            },
            {
              title: 'Tipo',
              dataIndex: 'type',
              key: 'type',
              width: '10%',
              render: (text, value, index) => {
                return <div key={index}>
                  {value.type && constants.transport_types[value.type] ? constants.transport_types[value.type].name : ''}
                </div>
              }
            },
            {
              title: 'Descripción',
              dataIndex: 'description',
              key: 'description',
              width: '25%',
            },
            {
              title: 'Cupo máximo',
              dataIndex: 'maximum_quota',
              key: 'maximum_quota',
              width: '10%',
            },
            {
              title: 'Habilitado',
              dataIndex: 'enabled',
              key: 'enabled',
              width: '15%',
              render: (text, value, index) => {
                return <PermissionWrapper 
                  key={index}
                  module={'mdu_business_config'} 
                  edit
                  fallback={ value.enabled ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-danger"></i>}
                >
                  <Switch onClick={e => onChangeEnabled(value)} checked={value.enabled ? true : false}/>
                </PermissionWrapper>
              }
            },
            {
              title: 'Acciones',
              dataIndex: 'actions',
              key: 'actions',
              width: '15%',
              render: (text, value, index) => {
                return <Space key={index}>
                  <PermissionWrapper module={'mdu_business_config'} edit>
                    <Tooltip title={'Editar'}>
                      <Button shape="circle" type="primary" onClick={ e => openForm(value)} icon={<EditOutlined/>}></Button>
                    </Tooltip>
                  </PermissionWrapper>
                  <PermissionWrapper module={'mdu_business_config'} delete>
                    <Tooltip title={'Eliminar'}>
                      <Button shape="circle" type="default" danger onClick={ e => deleteItem(value)} icon={<DeleteOutlined/>}></Button>
                    </Tooltip>
                  </PermissionWrapper>
                </Space>
              }
            }
          ]}
          rowKey={record => record.id}
          dataSource={tableState.data}
          pagination={tableState.pagination}
          loading={loading}
          scroll={{ x: 'auto' }}
          onChange={handleTableChange}
        />
      </Card>

      {
        formState.isOpen && 
        <TransportForm
          isVisible={formState.isOpen}
          data={formState.data}
          onCancel={closeForm}
          onSuccess={()=>{
            closeForm()
            refreshTable()
          }}
        />
      } */}

    </div>
  );
};

export default Settings;