import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Switch,
  Select,
  Tooltip,
  Modal,
  Input,
  Upload,
  message,
  Form,
  Radio,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import SubMenu from './SubMenu'
import BusinessService from 'src/services/BusinessService' // importamos los servicios para la API
import BookingService from 'src/services/BookingService' /// para obtener los recursos
import listMenu from './constants/menuConfig'; // para el menú de config

export default function SettingsRecursos({ settingFormInfo }) {
  const [form] = Form.useForm() // Inicializamos el hook Form

  // const [listMenu, setListMenu] = useState([
  //   { title: 'Ubicación', list: ['general', 'lista de espera', 'reservaciones'] },
  //   { title: 'Clientes', list: ['campos de entrada', 'etiquetas de entrada'] },
  //   { title: 'Equipo', list: ['usuarios', 'roles', 'recursos'] }, // Sección "Equipo"
  // ])

  const [recursos, setRecursos] = useState([])

  // Estado de la paginación
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Mostrar 10 elementos por página
    total: 0, // Este total se actualizará con la respuesta de la API
  })

  const [businessId, setBusinessId] = useState(null) // Estado para almacenar el business_id

  //declaraciones para el modal de edición
  const [isModalVisible, setIsModalVisible] = useState(false) // Para manejar la visibilidad del modal
  const [selectedResource, setSelectedResource] = useState(null) // Para almacenar el recurso seleccionado

  const [isEditMode, setIsEditMode] = useState(false) // Controla si estamos editando o agregando

  const [isMassUploadModalVisible, setIsMassUploadModalVisible] = useState(false)
  const [massUploadData, setMassUploadData] = useState({
    quantity: 0,
    type: 'mesa', // valor por defecto
    capacity: 1,
  })

  // Recuperamos el business_id de la respuesta a la API al cargar la vista
  useEffect(() => {
    const storedBusinessId = localStorage.getItem('provider_tappit_user_business_id')
    if (storedBusinessId) {
      setBusinessId(storedBusinessId)
      fetchResources(storedBusinessId, { current: 1, pageSize: pagination.pageSize })
    } else {
      console.error('No se encontró el businessId en localStorage')
    }
  }, [])

  //const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = (record = null) => {
    setSelectedResource(record) // Asigna el recurso si estamos editando, o null si estamos agregando
    if (record) {
      form.setFieldsValue({
        name: record.name,
        capacity: record.capacity,
        vip: record.vip,
        zone: record.zone,
        type: record.type,
        description: record.description,
      })
    } else {
      form.resetFields() // Resetea el formulario si estamos agregando un recurso nuevo
    }
    setIsModalVisible(true)
  }

  // función para actualizar tabla
  const fetchResources = async (paginationParams) => {
    try {
      const { current, pageSize } = paginationParams
      const businessId = localStorage.getItem('provider_tappit_user_business_id') // Leer siempre el businessId actualizado

      if (!businessId) {
        console.error('No se encontró el businessId en localStorage')
        return
      }

      const resourcesResponse = await BookingService.getAllResources(businessId, {
        page: current,
        limit: pageSize,
      })

      console.log('Recursos del negocio:', resourcesResponse)

      const filteredResources = resourcesResponse.data.rows.filter(
        (resource) => !resource.is_deleted,
      )

      setRecursos(filteredResources)
      setPagination((prev) => ({
        ...prev,
        total: resourcesResponse.data.count,
        current,
        pageSize,
      }))
    } catch (error) {
      console.error('Error al obtener los recursos:', error)
    }
  }

  // Aquí decidimos si agregamos o editamos recurso
  const handleOk = () => {
    form.validateFields().then((values) => {
      const businessId = localStorage.getItem('provider_tappit_user_business_id')
      if (!businessId) {
        console.error('No se encontró el businessId en localStorage')
        return
      }

      const resourceData = {
        ...values,
        is_deleted: false,
      }

      if (selectedResource) {
        // Edición
        BookingService.updateResource(businessId, selectedResource.id, resourceData)
          .then(() => {
            message.success('Recurso actualizado con éxito')
            setIsModalVisible(false)
            fetchResources({ current: pagination.current, pageSize: pagination.pageSize })
          })
          .catch(() => {
            message.error('Error al actualizar el recurso')
          })
      } else {
        // Creación
        BusinessService.createResource(businessId, resourceData)
          .then(() => {
            message.success('Recurso agregado con éxito')
            setIsModalVisible(false)
            fetchResources({ current: pagination.current, pageSize: pagination.pageSize })
          })
          .catch(() => {
            message.error('Error al agregar el recurso')
          })
      }
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // Muestra el modal y carga los datos del recurso seleccionado
  const showEditModal = (record) => {
    setSelectedResource(record) // Guarda el recurso seleccionado
    form.setFieldsValue({
      name: record.name,
      capacity: record.capacity,
      vip: record.vip,
      zone: record.zone,
      type: record.type,
      description: record.description,
    }) // Llena el formulario con los valores actuales del recurso
    setIsModalVisible(true)
  }

  // Función para eliminar un recurso
  const handleDelete = (resourceId) => {
    const businessId = localStorage.getItem('provider_tappit_user_business_id')
    if (!businessId) {
      console.error('No se encontró el businessId en localStorage')
      return
    }

    BookingService.deleteResource(businessId, resourceId)
      .then(() => {
        message.success('Recurso eliminado con éxito')
        const updatedRecursos = recursos.filter((recurso) => recurso.id !== resourceId)
        setRecursos(updatedRecursos)
      })
      .catch(() => {
        message.error('Error al eliminar el recurso')
      })
  }

  // Función para Agregar recursos masivamente
  const handleMassUpload = () => {
    console.log('Enviando datos para la carga masiva', massUploadData)
    BookingService.postMassiveResource(businessId, massUploadData)
      .then(() => {
        message.success('Recursos agregados exitosamente')
        setIsMassUploadModalVisible(false) // Cerrar el modal de carga masiva
        fetchResources(businessId, { current: pagination.current, pageSize: pagination.pageSize }) // Refrescar la tabla
      })
      .catch((error) => {
        console.error('Error al agregar recursos masivamente:', error)
        message.error('Error al agregar recursos masivamente')
      })
  }

  // Abrir modal para masivamentre
  const showMassUploadModal = () => {
    setIsMassUploadModalVisible(true)
  }

  // Cerrar modal para masivamentre
  const handleMassUploadCancel = () => {
    setIsMassUploadModalVisible(false)
  }

  // Incrementar cuenta modal
  const incrementQuantity = () => {
    setMassUploadData((prev) => ({ ...prev, quantity: prev.quantity + 1 }))
  }

  // Decrementar cuenta modal
  const decrementQuantity = () => {
    setMassUploadData((prev) => ({
      ...prev,
      quantity: prev.quantity > 1 ? prev.quantity - 1 : 1,
    }))
  }

  // cambios en tipo y casualidad
  const handleMassUploadChange = (key, value) => {
    setMassUploadData((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  // Cambios de página y paginación
  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination

    // Actualiza el estado de paginación antes de llamar a fetchResources
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize,
    }))

    // Llama a la función fetchResources con los nuevos parámetros
    fetchResources(businessId, {
      current,
      pageSize,
      sorter: sorter.order ? { field: sorter.field, order: sorter.order } : null,
    })
  }

  // Define las columnas de la tabla, incluyendo el botón de "Editar"
  const columns = [
    {
      title: 'Recurso',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <span>{record.name}</span>
        </span>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Select defaultValue={status} style={{ width: 120 }}>
          <Select.Option value="Disponible">Disponible</Select.Option>
          <Select.Option value="No Disponible">No Disponible</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Tooltip title="Editar">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showEditModal(record)} // Llama a showEditModal cuando se haga clic en "Editar"
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              className="ml-2"
              onClick={() => handleDelete(record.id)} // Para eliminar
            />
          </Tooltip>
        </span>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col xs={5}>
          <SubMenu settingsList={listMenu} />
        </Col>
        <Col xs={19} className="pl-2">
          {/* Sección de "Recursos" */}
          <Card className="bg-white rounded shadow-none">
            <h2 className="fw-bold">{settingFormInfo || 'Recursos'}</h2>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="mt-2"
              onClick={() => showModal(null)}
            >
              Agregar recurso
            </Button>

            {/* Botón para agregar recursos masivamente */}
            <Button
              type="default"
              icon={<PlusOutlined />}
              className="mt-2 ml-2"
              onClick={showMassUploadModal}
            >
              Agregar recursos masivamente
            </Button>
          </Card>

          {/* Tabla de Recursos */}
          <Card className="bg-white rounded shadow-none mt-4">
            <Select defaultValue="Mostrar todos los recursos" style={{ width: 200 }}>
              <Select.Option value="Todos">Todos</Select.Option>
              <Select.Option value="Sillones">Sillones</Select.Option>
              <Select.Option value="Mesas">Mesas</Select.Option>
            </Select>

            <Table
              columns={columns}
              dataSource={recursos}
              className="mt-4"
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize, // Usar el valor dinámico de `pagination`
                total: pagination.total,
                showSizeChanger: false, // Ocultar el selector de tamaño de página
                onChange: (page) => {
                  setPagination((prev) => ({ ...prev, current: page }))
                  fetchResources({ current: page, pageSize: pagination.pageSize })
                },
              }}
              onChange={handleTableChange}
            />
          </Card>

          {/* Configuración de recursos */}
          {/* <Card className="bg-white rounded shadow-none mt-4">
            <h3 className="font-semibold">Configuración</h3>
            <div className="form-group mt-2">
              <Switch defaultChecked /> Los recursos que han iniciado sesión solo pueden ver a los
              invitados a los que pueden atender
            </div>
            <div className="form-group mt-2">
              <Switch /> Asignación automática de recursos en alerta
            </div>
            <div className="form-group mt-2">
              <Switch /> Establezca automáticamente los recursos como Ausentes al final del día
            </div>
            <div className="form-group mt-2">
              <Switch /> Autocompletar clientes para recursos
            </div>
          </Card> */}

          {/* Visualización de categoría de recursos */}
          {/* <Card className="bg-white rounded shadow-none mt-4">
            <h3 className="font-semibold">Visualización de la categoría de recursos</h3>
            <Select defaultValue="Mostrar todos los recursos en un" style={{ width: '100%' }}>
              <Select.Option value="lista">Lista</Select.Option>
              <Select.Option value="categoría">Categoría</Select.Option>
            </Select>
          </Card> */}
        </Col>
      </Row>

      {/* Modal para agregar o editar recurso */}
      <Modal
        title={selectedResource ? 'Editar recurso' : 'Agregar recurso'} // Cambia el título según el caso
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Nombre del recurso"
                rules={[{ required: true, message: 'Ingrese el nombre del recurso' }]}
              >
                <Input placeholder="Ingrese el nombre del recurso" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="type"
                label="Categoría"
                rules={[{ required: true, message: 'Seleccione una categoría' }]}
              >
                <Select placeholder="Selecciona una categoría">
                  <Select.Option value="mesa">Mesa</Select.Option>
                  <Select.Option value="sillon">Sillón</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="capacity"
                label="Capacidad"
                rules={[{ required: true, message: 'Ingrese la capacidad' }]}
              >
                <Input placeholder="Capacidad (número de personas)" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="vip" label="VIP">
                <Radio.Group>
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="zone" label="Zona">
                <Input placeholder="Zona (ej: terraza, salón, etc.)" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="description" label="Descripción">
                <Input.TextArea placeholder="Descripción del recurso" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Modal masivamente */}
      <Modal
        title="Agregar recursos masivamente"
        visible={isMassUploadModalVisible}
        onOk={handleMassUpload}
        onCancel={handleMassUploadCancel}
        width={700}
      >
        <Form layout="vertical">
          <Form.Item label="Cantidad">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={decrementQuantity}>-</Button>
              <Input
                value={massUploadData.quantity !== null ? massUploadData.quantity : ''}
                onChange={(e) => {
                  const value = e.target.value
                  if (value === '' || !isNaN(parseInt(value, 10))) {
                    handleMassUploadChange('quantity', value) // Deja el valor temporalmente vacío xda
                  }
                }}
                onBlur={() => {
                  const parsedValue = parseInt(massUploadData.quantity, 10)
                  if (isNaN(parsedValue) || parsedValue < 1) {
                    handleMassUploadChange('quantity', 1) // Ajusta a 1 si el campo está vacío o no es válido
                  }
                }}
                style={{ width: '60px', margin: '0 10px' }}
              />
              <Button onClick={incrementQuantity}>+</Button>
            </div>
          </Form.Item>

          <Form.Item label="Tipo de recurso">
            <Select defaultValue="Mesa" onChange={(value) => handleMassUploadChange('type', value)}>
              <Select.Option value="mesa">Mesa</Select.Option>
              <Select.Option value="sillon">Sillón</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Capacidad">
            <Input
              type="number"
              min={1}
              value={massUploadData.capacity}
              onChange={(e) => handleMassUploadChange('capacity', parseInt(e.target.value))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
