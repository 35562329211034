import api from 'src/services/api'

const ClientRESTService = {
  capturePaypalPayment: (data) => api.post('/v1/client/capture-paypal-payment', data),
  
  sendSMS: (data) => api.post('/v1/client/enviar-sms', data),
  //getBookingByTicket: (ticket) => api.get(`/v1/client/booking?ticket=${ticket}`),
   getBookingByTicket: (business_id, ticket) => 
    api.get(`/v1/client/business/${business_id}/booking/?ticket=${ticket}&business_id=${business_id}`),

   //getBookingByPhoneNumber: (business_id, phoneNumber) => 
    //api.get(`/v1/client/business/${business_id}/booking/?customer_phone_number=${phoneNumber}&business_id=${business_id}`),
  
   getBookingByPhoneNumber: (business_id, phoneNumber) => 
    api.get(`/v1/client/business/${business_id}/telefono/?customer_phone_number=${phoneNumber}&business_id=${business_id}`),

}

export default ClientRESTService