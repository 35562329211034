import { Card, Row, Col, Input, Button, Checkbox, Select, Form, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import SubMenu from './SubMenu'
import { alerts } from 'src/utils'
import BusinessService from 'src/services/BusinessService'
import listMenu from './constants/menuConfig'; // para el menú de config

export default function SettingsGeneral({ settingFormInfo }) {
  const [form] = Form.useForm()
  const [businessId, setBusinessId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [selectedIconImage, setSelectedIconImage] = useState(null)

  // Cargar Business ID desde localStorage y llamar a getBusinessToEdit
  useEffect(() => {
    const storedBusinessId = localStorage.getItem('provider_tappit_user_business_id')
    if (storedBusinessId) {
      const parsedBusinessId = JSON.parse(storedBusinessId)
      setBusinessId(parsedBusinessId)
      getBusinessToEdit(parsedBusinessId)
    } else {
      console.error('No se encontró el businessId en localStorage')
    }
  }, [])

  // Obtener datos del negocio desde la API
  const getBusinessToEdit = async (id) => {
    setLoading(true)
    try {
      let response = await BusinessService.getOne(id)
      console.log('Respuesta completa:', response)

      if (response && response.data) {
        console.log('Datos de negocio obtenidos:', response.data)
        initializeForm(response.data)
      } else {
        console.warn('No se pudo obtener los datos del negocio')
        alerts.warn('No se pudo obtener los datos del negocio')
      }
    } catch (error) {
      console.error('Error al obtener los datos del negocio:', error)
      alerts.error('Ha ocurrido un error al obtener la información del negocio')
    }
    setLoading(false)
  }

  // Inicializar formulario con datos de la API
  const initializeForm = (data) => {
    form.setFieldsValue({
      name: data.name || '',
      location_url: data.location_url || '',
      icon_company: data.icon_company || '',
      email: data.email || '',
      address: data.address || '',
      colony: data.colony || '',
      city: data.city || '',
      postal_code: data.postal_code || '',
      phone: data.phone || '',
      website: data.website || '',
      type: data.type || '',
      manager: data.manager || '',
      description: data.description || '',
      latitude: data.latitude ? parseFloat(data.latitude) : '',
      longitude: data.longitude ? parseFloat(data.longitude) : '',
    })

    setCurrentImage(data.icon_company || '') // Actualiza la imagen si está disponible
  }

  // para la imagen
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  // Renderizado del formulario
  return (
    <>
      <Row>
        <Col xs={5}>
        <SubMenu settingsList={listMenu} />

        </Col>
        <Col xs={19} className="pl-2">
          {/* Configuración General */}
          <Card className="bg-white rounded shadow-none mb-4">
            <h2 className="fw-bold">{settingFormInfo || 'Configuración General'}</h2>
          </Card>

          {/* Formulario de configuración */}
          <Card className="bg-white rounded shadow-none mb-4">
            <Form form={form} layout="vertical">
              <Card className="bg-white rounded shadow-none mb-4">
                <h2 className="fw-bold mb-4">Configuración General</h2>

                {/* Ubicación */}
                <h3>Ubicación</h3>
                <p className="text-muted mb-4">
                  El nombre de su ubicación se muestra en muchas áreas, incluso en sus páginas en
                  línea y mensajes a cliente.
                </p>

                <Form.Item label="Nombre del lugar" name="name">
                  <Input placeholder="Nombre del lugar" />
                </Form.Item>

                <Form.Item label="ID y URL de la ubicación" name="location_url">
                  <Input
                    addonAfter={<Button type="primary">Editar ID de ubicación</Button>}
                    placeholder="tappit.com/welcome/nombre-del-lugar"
                  />
                </Form.Item>

                <Form.Item name="isPublic" valuePropName="checked">
                  <Checkbox>Establecer URL de ubicación como pública</Checkbox>
                </Form.Item>

                {/* Logo de la compañía */}
                <h3 className="mt-4">Logo de la compañía</h3>
                <p className="text-muted mb-4">
                  El logotipo se destaca de forma destacada en sus páginas públicas y otros
                  materiales orientados al cliente.
                </p>

                <div className="form-group d-flex align-items-center">
                  <img
                    src={currentImage || 'ruta-al-logo-predeterminado'}
                    alt="Logo de la compañía"
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      marginRight: '16px',
                    }}
                  />
                  <div>
                    <Button
                      type="default"
                      onClick={() => setCurrentImage('/ruta/al/nuevo/logo.png')}
                      className="mr-2"
                    >
                      Navegar
                    </Button>
                    <Button type="danger">Eliminar</Button>
                  </div>
                </div>

                {/* Icono de ubicación */}
                <h3 className="mt-4">Icono de ubicación</h3>
                <p className="text-muted mb-4">
                  El icono se usa para distinguir sus ubicaciones tanto internamente como en páginas
                  públicas.
                </p>

                <Form.Item
                  name="location_icon"
                  label="Icono de ubicación"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[{ required: false }]}
                >
                  <Upload
                    name="file_icon"
                    listType="picture"
                    maxCount={1}
                    beforeUpload={(file) => {
                      setSelectedIconImage(URL.createObjectURL(file))
                      return false
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Seleccionar imagen</Button>
                  </Upload>
                </Form.Item>

                {selectedIconImage && (
                  <img
                    src={selectedIconImage}
                    alt="Icono de ubicación"
                    style={{ width: '100px', marginTop: '10px' }}
                  />
                )}
              </Card>
            </Form>
          </Card>

          {/* Sección de Horas */}
          <Card className="bg-white rounded shadow-none mt-4">
            <h3 className="fw-semibold">Horas</h3>
            <p style={{ color: '#6c757d' }}>Establece tu horario de apertura y zona horaria.</p>

            <Form layout="vertical">
              {/* Zona horaria */}
              <Form.Item label="Zona horaria" name="timezone">
                <Select placeholder="Selecciona la zona horaria" style={{ width: '100%' }}>
                  <Select.Option value="gmt-5">GMT-5</Select.Option>
                  <Select.Option value="gmt-6">GMT-6</Select.Option>
                  <Select.Option value="gmt-7">GMT-7</Select.Option>
                </Select>
              </Form.Item>

              {/* Horario de apertura */}
              <h4 className="fw-semibold mt-4">Horario de apertura</h4>
              <div style={{ marginBottom: '16px' }}>
                <ul style={{ paddingLeft: '0', listStyleType: 'none' }}>
                  <li>Lun 10:00 - 20:00</li>
                  <li>Mar 10:00 - 20:00</li>
                  <li>Mié 10:00 - 20:00</li>
                  <li>Jue 10:00 - 20:00</li>
                  <li>Vie 10:00 - 20:00</li>
                  <li>Sáb Todo el día</li>
                  <li>Dom Todo el día</li>
                </ul>
                <Button type="default">Establecer horario de apertura</Button>
              </div>

              {/* Fechas específicas */}
              <h4 className="fw-semibold mt-4">Fechas específicas</h4>
              <Button type="default" style={{ width: '100%' }}>
                Establecer horas por fecha
              </Button>
            </Form>
          </Card>

          {/* Sección de Información Comercial */}
          <Card className="bg-white rounded shadow-none mt-4">
            <Row>
              <Col xs={12}>
                <h3 className="font-semibold">Información comercial</h3>
                <p style={{ color: '#6c757d' }}>
                  La información de su empresa se muestra en sus páginas en línea y mensajes a
                  clientes
                </p>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <p>
                      <strong>Nombre del negocio:</strong>
                    </p>
                    <p>
                      <strong>Tipo de negocio:</strong>
                    </p>
                    <p>
                      <strong>Teléfono de negocios:</strong>
                    </p>
                    <p>
                      <strong>Email de negocios:</strong>
                    </p>
                    <p>
                      <strong>Sitio web de negocios:</strong>
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p>{form.getFieldValue('name') || '-'}</p>
                    <p>{form.getFieldValue('type') || '-'}</p>
                    <p>{form.getFieldValue('phone') || '-'}</p>
                    <p>{form.getFieldValue('email') || '-'}</p>
                    <p>{form.getFieldValue('website') || '-'}</p>
                  </Col>
                </Row>
                <Button type="default" className="mt-4">
                  Editar información comercial
                </Button>
              </Col>
            </Row>
          </Card>

          {/* Sección de Dirección de Negocios */}
          <Card className="bg-white rounded shadow-none mt-4">
            <Row>
              <Col xs={12}>
                <h3 className="font-semibold">Dirección de Negocios</h3>
                <p style={{ color: '#6c757d' }}>Texto de información de dirección comercial</p>
              </Col>
              <Col xs={12}>
                <Form.Item name="address" label="Dirección">
                  <Input placeholder="Dirección" />
                </Form.Item>
                <Form.Item name="colony" label="Colonia">
                  <Input placeholder="Colonia" />
                </Form.Item>
                <Form.Item name="city" label="Ciudad">
                  <Input placeholder="Ciudad" />
                </Form.Item>
                <Form.Item name="postal_code" label="Código postal">
                  <Input placeholder="Código postal" />
                </Form.Item>
                <Form.Item name="country_id" label="País">
                  <Select placeholder="Selecciona el país">
                    <Select.Option value="1">México</Select.Option>
                    <Select.Option value="2">USA</Select.Option>
                    <Select.Option value="3">Canadá</Select.Option>
                    {/* Agregar más opciones según la configuración */}
                  </Select>
                </Form.Item>
                <Form.Item name="state_province" label="Provincia del estado">
                  <Select placeholder="Selecciona la provincia">
                    <Select.Option value="chihuahua">Chihuahua</Select.Option>
                    <Select.Option value="nuevo-leon">Nuevo León</Select.Option>
                    <Select.Option value="jalisco">Jalisco</Select.Option>
                    {/* Agregar más opciones según la configuración */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}
